import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import data from '../../data/contact';

const ContactIcons = () => (
  <ul className="fa-ul">
    {data.map((s) => (
      <li key={s.label}>
        <a href={s.link}>
          {(typeof s.icon === 'string' || s.icon instanceof String) ? <i className={s.icon} /> : <FontAwesomeIcon icon={s.icon} />}&nbsp;&nbsp;&nbsp;{s.label}
        </a>
      </li>
    ))}
  </ul>
);

export default ContactIcons;
