const routes = [
  {
    index: true,
    label: "RUIDONG ZHANG",
    path: '/',
  },
  {
    label: 'Research',
    path: '/research',
  },
  {
    label: 'Talks',
    path: '/talks',
  },
  {
    label: 'Teaching',
    path: '/teaching',
  },
  {
    label: 'CV',
    path: '/cv',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
