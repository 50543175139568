import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/profile_square.jpg`} alt="" title="Photo courtesy of Louis DiPietro"/>
      </Link>
      <header>
        <h2>RUIDONG ZHANG</h2>
        <p className='email'>rz379 AT cornell DOT edu</p>
      </header>
    </section>

    <section className="blurb">
      <h2>ABOUT</h2>
      <p>Ph.D. Candidate in Information Science, Cornell University</p>
      <ContactIcons />
      {/* <ul class="fa-ul">
        <li><span class="fa-li"><i class="fa-solid fa-location-dot"></i></span>Ithaca, NY, USA</li>
        <li><a href="https://scholar.google.com/citations?hl=en&user=h1PhB-sAAAAJ"><span class="fa-li"><i class="fas fa-fw fa-graduation-cap"></i></span>Google Scholar</a></li>
      </ul> */}
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/cv') ? <Link to="/cv" className="button">Learn More</Link> : <Link to="/" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <p className="copyright">&copy; Ruidong Zhang <Link to="/">ruidongzhang.com</Link>. Template from <Link to="https://github.com/mldangelo/personal-site">Michael D'Angelo</Link></p>
    </section>
  </section>
);

export default SideBar;
