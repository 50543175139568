import React from "react";

function underlineName(inputString) {
  const underline = "<u>Ruidong Zhang</u>";
  const parts = inputString.split("Ruidong Zhang");
  return (
    <React.Fragment>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index !== parts.length - 1 && (
            <span dangerouslySetInnerHTML={{ __html: underline }} />
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default underlineName;
