import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import data from '../data/talks';
import underlineName from "../components/Projects/underlineName";
import Main from '../layouts/Main';
import { Link } from 'react-router-dom';

// import projects from

const TalkDetails = () => {
  const { link } = useParams();
  const talk = data.find((p) => p.link === `/talks/${link}`);
  return (
    <Main
      title="Talks"
      description="A list of my talks."
    >
    {/* <div className="cell-container"> */}
      <article className="post">
        <header>
          <div className="title">
            <h2>{talk.title}</h2>
            {/* {talk.subtitle} */}
            <p>{talk.subtitle}</p>
            {/* <time className="published">{dayjs(talk.date).format('MMMM, YYYY')} @ {talk.location}</time> */}
            {/* <p>{talk.pub_status} in {talk.venue}</p> */}
          </div>
        </header>
        <img src={`${process.env.PUBLIC_URL}${talk.image}`} width="100%" alt={talk.title} />
        <div className="description">
          <p><b>Abstract:</b></p>
          <p>{talk.abstract}</p>
        </div>
        {
          'video' in talk ?
          <div className="video-container">
            <p><b>Video:</b></p>
            <iframe width="560" height="315" src={talk.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div> : 
          ''
        }

        {
          'project' in talk ?
          <div className="description">
            <p><b>Learn more about the project in the talk:&nbsp;&nbsp;&nbsp;</b><a href={`/research/${talk.project}`}>{talk.project}</a></p>
          </div> : 
          ''
        }
        {/* <div className="description">
          <p><b>Reference:</b></p>
          <p>{underlineName(talk.authors)}.&nbsp;{talk.year}.&nbsp;{talk.paper_title}.&nbsp;In <i>{talk.journal}</i>.&nbsp;{talk.publisher}.&nbsp;<a href={talk.doi}>{talk.doi}</a></p>
        </div> */}
        {/* {
          'media_coverage' in talk ?
          <div className='media_coverage'>
            <p><b>Media coverage:</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {talk.media_coverage.map((m, index) => (
                <Fragment key={index}>
                  <Link to={m.url}>{m.source}</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                </Fragment>
              ))}
            </p>
          </div> :
          <></>
        } */}
        {/* <div className="description">
          <p></p>
          <Link to={'pdf' in talk ? talk.pdf : talk.doi} className="button">Download paper</Link>
          <p></p>
        </div> */}
      </article>
    {/* </div> */}
    </Main>
  );
};

// ProjectDetails.propTypes = {
//   data: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     link: PropTypes.string,
//     image: PropTypes.string.isRequired,
//     date: PropTypes.string.isRequired,
//     desc: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default TalkDetails;
