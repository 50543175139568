// TODO Add a couple lines about each project
const data = [

  {
    title: 'VRoxy',
    venue: 'UIST \'23',
    subtitle: 'Published at UIST \'23',
    image: '/images/projects/vroxy.jpg',
    link: '/research/VRoxy',
    date: '2023-10-29',
    desc:
      'Wide-Area Collaboration From an Office Using a VR-Driven Robotic Proxy',

    paper_title: 'VRoxy: Wide-Area Collaboration From an Office Using a VR-Driven Robotic Proxy',
    authors: 'Mose Sakashita, Hyunju Kim, Brandon Woodard, Ruidong Zhang, and François Guimbretière',
    year: '2023',
    journal: 'Proceedings of the 36th Annual ACM Symposium on User Interface Software and Technology (UIST \'23)',
    publisher: 'Association for Computing Machinery, New York, NY, USA, Article 59, 1-13',
    doi: 'https://doi.org/10.1145/3586183.3606743',
    video: 'https://www.youtube.com/embed/1hpaASC_Xls',
    pub_status: 'Published',
    // pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'Recent research in robotic proxies has demonstrated that one can automatically reproduce many non-verbal cues important in co-located '
      + 'collaboration. However, they often require a symmetrical hardware setup in each location. We present the VRoxy system, designed to '
      + 'enable access to remote spaces through a robotic embodiment, using a VR headset in a much smaller space, such as a personal office. '
      + 'VRoxy maps small movements in VR space to larger movements in the physical space of the robot, allowing the user to navigate large '
      + 'physical spaces easily. Using VRoxy, the VR user can quickly explore and navigate in a low-fidelity rendering of the remote space. '
      + 'Upon the robot\'s arrival, the system uses the feed of a 360 camera to support real-time interactions. The system also facilitates '
      + 'various interaction modalities by rendering the micro-mobility around shared spaces, head and facial animations, and pointing gestures '
      + 'on the proxy. We demonstrate how our system can accommodate mapping multiple physical locations onto a unified virtual space. In a '
      + 'formative study, users could complete a design decision task where they navigated and collaborated in a complex 7.5m x 5m layout '
      + 'using a 3m x 2m VR space.'

    // media_coverage: [
    //   {
    //     source: 'Cornell Chronicle',
    //     url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
    //   },
    //   {
    //     source: 'Engadget',
    //     url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
    //   },
    //   // {
    //   //   source: 'Hackster',
    //   //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
    //   // },
    //   // {
    //   //   source: 'New Atlas',
    //   //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
    //   // },
    //   // {
    //   //   source: 'Interesting Engineering',
    //   //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
    //   // },
    // ],
  },
  {
    title: 'PoseSonic',
    venue: 'IMWUT/Ubicomp \'23',
    subtitle: 'Published at IMWUT \'23',
    image: '/images/projects/posesonic.png',
    link: '/research/PoseSonic',
    date: '2023-09-27',
    desc:
      '3D Upper Body Pose Estimation Through Egocentric Acoustic Sensing on Smartglasses',

    paper_title: 'PoseSonic: 3D Upper Body Pose Estimation Through Egocentric Acoustic Sensing on Smartglasses',
    authors: 'Saif Mahmud, Ke Li, Guilin Hu, Hao Chen, Richard Jin, Ruidong Zhang, François Guimbretière, and Cheng Zhang',
    year: '2023',
    journal: 'Proc. ACM Interact. Mob. Wearable Ubiquitous Technol',
    publisher: '7, 3, Article 111 (September 2023), 28 pages',
    doi: 'https://doi.org/10.1145/3610895',
    video: 'https://www.youtube.com/embed/Q8KV1XfeBCE',
    pub_status: 'Published',
    // pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'In this paper, we introduce PoseSonic, an intelligent acoustic sensing solution for smartglasses that estimates '
      + 'upper body poses. Our system only requires two pairs of microphones and speakers on the hinges of the eyeglasses '
      + 'to emit FMCW-encoded inaudible acoustic signals and receive reflected signals for body pose estimation. Using a '
      + 'customized deep learning model, PoseSonic estimates the 3D positions of 9 body joints including the shoulders, '
      + 'elbows, wrists, hips, and nose. We adopt a cross-modal supervision strategy to train our model using synchronized '
      + 'RGB video frames as ground truth. We conducted in-lab and semi-in-the-wild user studies with 22 '
      + 'participants to evaluate PoseSonic, and our user-independent model achieved a mean per joint position error of 6.17 '
      + 'cm in the lab setting and 14.12 cm in semi-in-the-wild setting when predicting the 9 body joint positions in '
      + '3D. Our further studies show that the performance was not significantly impacted by different surroundings or when the '
      + 'devices were remounted or by real-world environmental noise. Finally, we discuss the opportunities, challenges, and '
      + 'limitations of deploying PoseSonic in real-world applications.',

    // media_coverage: [
    //   {
    //     source: 'Cornell Chronicle',
    //     url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
    //   },
    //   {
    //     source: 'Engadget',
    //     url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
    //   },
    //   // {
    //   //   source: 'Hackster',
    //   //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
    //   // },
    //   // {
    //   //   source: 'New Atlas',
    //   //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
    //   // },
    //   // {
    //   //   source: 'Interesting Engineering',
    //   //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
    //   // },
    // ],
  },
  {
    title: 'HPSpeech',
    venue: 'ISWC \'23',
    subtitle: 'Published at ISWC \'23 | Best Paper Honorable Mention',
    image: '/images/projects/hpspeech.jpg',
    link: '/research/HPSpeech',
    date: '2023-10-08',
    desc:
      'Silent Speech Interface for Commodity Headphones',

    paper_title: 'HPSpeech: Silent Speech Interface for Commodity Headphones',
    authors: 'Ruidong Zhang, Hao Chen, Devansh Agarwal, Richard Jin, Ke Li, François Guimbretière, and Cheng Zhang',
    year: '2023',
    journal: 'Proceedings of the 2023 ACM International Symposium on Wearable Computers (ISWC \'23), Oct 8-12, 2023, Cancún, Mexico',
    publisher: 'Association for Computing Machinery, New York, NY, USA, 60-65',
    doi: 'https://doi.org/10.1145/3594738.3611365',
    video: 'https://www.youtube.com/embed/YIjK-XLrves',
    pub_status: 'Published',
    // pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'We present HPSpeech, a silent speech interface for commodity headphones. HPSpeech utilizes the existing speakers '
      + 'of the headphones to emit inaudible acoustic signals. The movements of the temporomandibular joint (TMJ) during '
      + 'speech modify the reflection pattern of these signals, which are captured by a microphone positioned inside the '
      + 'headphones. To evaluate the performance of HPSpeech, we tested it on two headphones with a total of 18 participants. '
      + 'The results demonstrated that HPSpeech successfully recognized 8 popular silent speech commands for controlling the '
      + 'music player with an accuracy over 90%. While our tests use modified commodity hardware (both with and without active '
      + 'noise cancellation), our results show that sensing the movement of the TMJ could be as simple as a firmware update for '
      + 'ANC headsets which already include a microphone inside the hear cup. This leaves us to believe that this technique has '
      + 'great potential for rapid deployment in the near future. We further discuss the challenges that need to be addressed '
      + 'before deploying HPSpeech at scale.',

    // media_coverage: [
    //   {
    //     source: 'Cornell Chronicle',
    //     url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
    //   },
    //   {
    //     source: 'Engadget',
    //     url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
    //   },
    //   // {
    //   //   source: 'Hackster',
    //   //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
    //   // },
    //   // {
    //   //   source: 'New Atlas',
    //   //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
    //   // },
    //   // {
    //   //   source: 'Interesting Engineering',
    //   //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
    //   // },
    // ],
  },
  {
    title: 'C-Auth',
    venue: 'ISWC \'23',
    subtitle: 'Published at ISWC \'23',
    image: '/images/projects/cauth.png',
    link: '/research/C-Auth',
    date: '2023-10-08',
    desc:
      'Exploring the Feasibility of Using Egocentric View of Face Contour for User Authentication on Glasses',

    paper_title: 'C-Auth: Exploring the Feasibility of Using Egocentric View of Face Contour for User Authentication on Glasses',
    authors: 'Hyunchul Lim, Guilin Hu, Richard Jin, Hao Chen, Ryan Mao, Ruidong Zhang, and Cheng Zhang',
    year: '2023',
    journal: 'Proceedings of the 2023 ACM International Symposium on Wearable Computers (ISWC \'23), Oct 8-12, 2023, Cancún, Mexico',
    publisher: 'Association for Computing Machinery, New York, NY, USA, 6-10',
    doi: 'https://doi.org/10.1145/3594738.3611355',
    // video: 'https://www.youtube.com/embed/ZjucAwFqVqQ',
    pub_status: 'Published',
    // pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'In this paper, we present C-Auth, a novel authentication method for smart glasses that explores the feasibility '
      + 'of authenticating users using spatial facial information. Our system uses a down-facing camera in the middle of '
      + 'the glasses to capture facial contour lines from the nose and cheeks. The images captured by the camera are then '
      + 'processed and learned by a customized algorithm for authentication. To evaluate the system, we conducted a user '
      + 'study with 20 participants in three sessions on different days. Our system correctly identified the 20 users with '
      + 'a true positive rate of 98.0\% (SD: 2.96\%) and a false positive rate of 4.97\% (2.88 \%) across all three days. '
      + 'We conclude by discussing current limitations and challenges as well as the potential future applications for C-Auth.',

    // media_coverage: [
    //   {
    //     source: 'Cornell Chronicle',
    //     url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
    //   },
    //   {
    //     source: 'Engadget',
    //     url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
    //   },
    //   // {
    //   //   source: 'Hackster',
    //   //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
    //   // },
    //   // {
    //   //   source: 'New Atlas',
    //   //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
    //   // },
    //   // {
    //   //   source: 'Interesting Engineering',
    //   //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
    //   // },
    // ],
  },
  {
    title: 'EchoNose',
    venue: 'ISWC \'23',
    subtitle: 'Published at ISWC \'23',
    image: '/images/projects/echonose.png',
    link: '/research/EchoNose',
    date: '2023-10-08',
    desc:
      'Sensing Mouth, Breathing and Tongue Gestures inside Oral Cavity using a Non-contact Nose Interface',

    paper_title: 'EchoNose: Sensing Mouth, Breathing and Tongue Gestures inside Oral Cavity using a Non-contact Nose Interface',
    authors: 'Rujia Sun, Xiaohe Zhou, Benjamin Steeper, Ruidong Zhang, Sicheng Yin, Ke Li, Shengzhang Wu, Sam Tilsen, François Guimbretière, and Cheng Zhang',
    year: '2023',
    journal: 'Proceedings of the 2023 ACM International Symposium on Wearable Computers (ISWC \'23), Oct 8-12, 2023, Cancún, Mexico',
    publisher: 'Association for Computing Machinery, New York, NY, USA, 22-26',
    doi: 'https://doi.org/10.1145/3594738.3611358',
    // video: 'https://www.youtube.com/embed/ZjucAwFqVqQ',
    pub_status: 'Published',
    // pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'Sensing movements and gestures inside the oral cavity has been a long-standing challenge for the wearable research '
      + 'community. This paper introduces EchoNose, a novel nose interface that explores a unique sensing approach to '
      + 'recognize gestures related to mouth, breathing, and tongue by analyzing the acoustic signal reflections inside '
      + 'the nasal and oral cavities. The interface incorporates a speaker and a microphone placed at the nostrils, emitting '
      + 'inaudible acoustic signals and capturing the corresponding reflections. These received signals were processed using '
      + 'a customized data processing and machine learning pipeline, enabling the distinction of 16 gestures involving speech, '
      + 'tongue, and breathing. A user study with 10 participants demonstrates that EchoNose achieves an average accuracy of '
      + '93.7% in recognizing these 16 gestures. Based on these promising results, we discuss the potential opportunities and '
      + 'challenges associated with applying this innovative nose interface in various future applications.',

    // media_coverage: [
    //   {
    //     source: 'Cornell Chronicle',
    //     url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
    //   },
    //   {
    //     source: 'Engadget',
    //     url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
    //   },
    //   // {
    //   //   source: 'Hackster',
    //   //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
    //   // },
    //   // {
    //   //   source: 'New Atlas',
    //   //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
    //   // },
    //   // {
    //   //   source: 'Interesting Engineering',
    //   //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
    //   // },
    // ],
  },
  {
    title: 'EchoSpeech',
    venue: 'CHI \'23',
    subtitle: 'Published at CHI \'23',
    image: '/images/projects/echospeech.jpg',
    link: '/research/EchoSpeech',
    date: '2023-01-17',
    desc:
      'Continuous Silent Speech Recognition on Minimally-obtrusive Eyewear Powered by Acoustic Sensing',

    paper_title: 'EchoSpeech: Continuous Silent Speech Recognition on Minimally-obtrusive Eyewear Powered by Acoustic Sensing',
    authors: 'Ruidong Zhang, Ke Li, Yihong Hao, Yufan Wang, Zhengnan Lai, François Guimbretière, and Cheng Zhang',
    year: '2023',
    journal: 'Proceedings of the 2023 CHI Conference on Human Factors in Computing Systems (CHI \'23), April 23-28, 2023, Hamburg, Germany',
    publisher: 'ACM, New York, NY, USA, 18 pages',
    doi: 'https://doi.org/10.1145/3544548.3580801',
    video: 'https://www.youtube.com/embed/ZjucAwFqVqQ',
    pub_status: 'Published',
    pdf: '/files/papers/EchoSpeech_authors_version.pdf',
    abstract:
      'We present EchoSpeech, a minimally-obtrusive silent speech interface (SSI) powered by low-power'
      + ' active acoustic sensing. EchoSpeech uses speakers and microphones mounted on a glass-frame and'
      + ' emits inaudible sound waves towards the skin. By analyzing echos from multiple paths, EchoSpeech'
      + ' captures subtle skin deformations caused by silent utterances and uses them to infer silent speech.'
      + ' With a user study of 12 participants, we demonstrate that EchoSpeech can recognize 31 isolated'
      + ' commands and 3-6 figure connected digits with 4.5% (std 3.5%) and 6.1% (std 4.2%) Word Error Rate'
      + ' (WER), respectively. We further evaluated EchoSpeech under scenarios including walking and noise'
      + ' injection to test its robustness. We then demonstrated using EchoSpeech in demo applications in'
      + ' real-time operating at 73.3mW, where the real-time pipeline was implemented on a smartphone with'
      + ' only 1-6 minutes of training data. We believe that EchoSpeech takes a solid step towards'
      + ' minimally-obtrusive wearable SSI for real-life deployment.',

    media_coverage: [
      {
        source: 'Cornell Chronicle',
        url: 'https://news.cornell.edu/stories/2023/04/ai-equipped-eyeglasses-can-read-silent-speech',
      },
      {
        source: 'Engadget',
        url: 'https://www.engadget.com/researchers-built-sonar-glasses-that-track-facial-movements-for-silent-communication-171508573.html',
      },
      // {
      //   source: 'Hackster',
      //   url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
      // },
      // {
      //   source: 'New Atlas',
      //   url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
      // },
      // {
      //   source: 'Interesting Engineering',
      //   url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
      // },
    ],
  },
  {
    title: 'ReMotion',
    venue: 'CHI \'23',
    subtitle: 'Published at CHI \'23',
    link: '/research/ReMotion',
    image: '/images/projects/remotion.jpg',
    date: '2023-01-17',
    desc:
      'Supporting Remote Collaboration in Open Space with Automatic Robotic Embodiment',

    paper_title: 'ReMotion: Supporting Remote Collaboration in Open Space with Automatic Robotic Embodiment',
    authors: 'Mose Sakashita, Ruidong Zhang, Xiaoyi Li, Hyunju Kim, Michael Russo, Cheng Zhang, Malte F. Jung, François Guimbretière',
    year: '2023',
    journal: 'Proceedings of the 2023 CHI Conference on Human Factors in Computing Systems (CHI \'23), April 23-28, 2023, Hamburg, Germany',
    publisher: 'ACM, New York, NY, USA, 14 pages',
    doi: 'https://doi.org/10.1145/3544548.3580699',
    video: 'https://www.youtube.com/embed/Sy0UpruV6C4',
    pub_status: 'Published',
    pdf: '/files/papers/ReMotion_author_version.pdf',
    abstract:
      'Design activities, such as brainstorming or critique, often take place in open spaces combining '
      + 'whiteboards and tables to present artefacts. In co-located settings, peripheral awareness enables '
      + 'participants to understand each other\'s locus of attention with ease. However, these spatial cues '
      + 'are mostly lost while using videoconferencing tools. Telepresence robots could bring back a sense '
      + 'of presence, but controlling them is distracting. To address this problem, we present ReMotion, '
      + 'a fully automatic robotic proxy designed to explore a new way of supporting non-collocated open-space '
      + 'design activities. ReMotion combines a commodity body tracker (Kinect) to capture a user\'s location '
      + 'and orientation over a wide area with a minimally invasive wearable system (NeckFace) to capture facial '
      + 'expressions. Due to its omnidirectional platform, ReMotion embodiment can render a wide range of body '
      + 'movements. A formative evaluation indicated that our system enhances the sharing of attention and the '
      + 'sense of co-presence enabling seamless movement-in-space during a design review task.',
  },
  {
    title: 'D-Touch',
    venue: 'IUI \'23',
    subtitle: 'Published at IUI \'23',
    link: '/research/D-Touch',
    image: '/images/projects/dtouch.jpg',
    date: '2023-01-16',
    desc:
      'Recognizing and Predicting Fine-grained Hand-face Touching Activities Using a Neck-mounted Wearable',
    
    paper_title: 'D-Touch: Recognizing and Predicting Fine-grained Hand-face Touching Activities Using a Neck-mounted Wearable',
    authors: 'Hyunchul Lim, Ruidong Zhang, Samhita Pendyal, Jeyeon Jo, and Cheng Zhang',
    year: '2023',
    journal: '28th International Conference on Intelligent User Interfaces (IUI \'23), March 27-31, 2023, Sydney, NSW, Australia',
    publisher: 'ACM, New York, NY, USA, 15 pages',
    doi: 'https://doi.org/10.1145/3581641.3584063',
    pub_status: 'Published',
    abstract:
      'This paper presents D-Touch, a neck-mounted wearable sensing system that can recognize and predict how '
      + 'a hand touches the face. It uses a neck-mounted infrared camera (IR), which takes pictures of the head '
      + 'from the neck. These IR camera images are processed and used to train a deep-learning model to recognize '
      + 'and predict touch time and positions. The study showed D-Touch distinguished 17 Facial related Activity '
      + '(FrA), including 11 face touch positions and 6 other activities, with over 92.1% accuracy and predict the '
      + 'hand-touching T-zone from other FrA activities with an accuracy of 82.12% within 150 ms after the hand '
      + 'appeared in the camera. A study with 10 participants conducted in their homes without any constraints '
      + 'on participants showed that D-Touch can predict the hand-touching T-zone from other FrA activities with '
      + 'an accuracy of 72.3% within 150 ms after the camera saw the hand. Based on the study results, we further '
      + 'discuss the opportunities and challenges of deploying D-Touch in real-world scenarios.',
  },
  {
    title: 'EatingTrak',
    venue: 'MobileHCI \'22',
    subtitle: 'Published at MobileHCI \'22',
    link: '/research/EatingTrak',
    image: '/images/projects/eatingtrak.jpg',
    date: '2022-09-20',
    desc:
      'Detecting Fine-grained Eating Moments in the Wild Using a Wrist-mounted IMU',

    paper_title: 'EatingTrak: Detecting Fine-grained Eating Moments in the Wild Using a Wrist-mounted IMU',
    authors: 'Ruidong Zhang, Jihai Zhang, Nitish Gade, Peng Cao, Seyun Kim, Junchi Yan, and Cheng Zhang',
    year: '2022',
    journal: 'Proc. ACM Hum.- Comput. Interact',
    publisher: '6, MHCI, Article 214 (September 2022), 22 pages',
    doi: 'https://doi.org/10.1145/3546749',
    pub_status: 'Published',
    abstract:
      'In this paper, we present EatingTrak, an AI-powered sensing system using a wrist-mounted inertial '
      + 'measurement unit (IMU) to recognize eating moments in a near-free-living semi-wild setup. It '
      + 'significantly improves the SOTA in time resolution using similar hardware on identifying eating '
      + 'moments, from over five minutes to three seconds. Different from prior work which directly learns '
      + 'from raw IMU data, it proposes intelligent algorithms which can estimate the arm posture in 3D in '
      + 'the wild and then learns the detailed eating moments from the series of estimated arm postures. '
      + 'To evaluate the system, we collected eating activity data from 9 participants in semi-wild scenarios '
      + 'for over 113 hours. Results showed that it was able to recognize eating moments at three '
      + 'time-resolutions: 3 seconds and 15 minutes with F-1 scores of 73.7% and 83.8%, respectively. '
      + 'EatingTrak would introduce new opportunities in sensing detailed eating behavior information '
      + 'requiring high time resolution, such as eating frequency, snack-taking, on-site behavior '
      + 'intervention. We also discuss the opportunities and challenges in deploying EatingTrak on '
      + 'commodity devices at scale.',
  },
  {
    title: 'BodyTrak',
    venue: 'IMWUT/Ubicomp \'22',
    subtitle: 'Published at IMWUT/Ubicomp \'22',
    link: '/research/BodyTrak',
    image: '/images/projects/bodytrak.jpg',
    date: '2022-09-20',
    desc:
      'Inferring Full-body Poses from Body Silhouettes Using a Miniature Camera on a Wristband',

    paper_title: 'BodyTrak: Inferring Full-body Poses from Body Silhouettes Using a Miniature Camera on a Wristband',
    authors: 'Hyunchul Lim, Yaxuan Li, Matthew Dressa, Fang Hu, Jae Hoon Kim, Ruidong Zhang, and Cheng Zhang',
    year: '2022',
    journal: 'Proc. ACM Interact. Mob. Wearable Ubiquitous Technol',
    publisher: '6, 3, Article 154 (September 2022), 21 pages',
    doi: 'https://doi.org/10.1145/3552312',
    pub_status: 'Published',
    abstract:
      'In this paper, we present BodyTrak, an intelligent sensing technology that can estimate full body '
      + 'poses on a wristband. It only requires one miniature RGB camera to capture the body silhouettes, '
      + 'which are learned by a customized deep learning model to estimate the 3D positions of 14 joints on '
      + 'arms, legs, torso, and head. We conducted a user study with 9 participants in which each participant '
      + 'performed 12 daily activities such as walking, sitting, or exercising, in varying scenarios (wearing '
      + 'different clothes, outdoors/indoors) with a different number of camera settings on the wrist. The '
      + 'results show that our system can infer the full body pose (3D positions of 14 joints) with an '
      + 'average error of 6.9 cm using only one miniature RGB camera (11.5mm x 9.5mm) on the wrist '
      + 'pointing towards the body. Based on the results, we disscuss the possible application, challenges, '
      + 'and limitations to deploy our system in real-world scenarios.',
    media_coverage: [
      {
        source: 'Cornell Chronicle',
        url: 'https://news.cornell.edu/stories/2022/11/wrist-mounted-camera-captures-entire-body-3d',
      },
      {
        source: 'CNET',
        url: 'https://www.cnet.com/tech/unique-wearable-tracker-can-detect-the-whole-body-in-3d/',
      },
      {
        source: 'Hackster',
        url: 'https://www.hackster.io/news/you-ain-t-seen-nothin-yet-78f6b7ee219a',
      },
      {
        source: 'New Atlas',
        url: 'https://newatlas.com/wearables/bodytrak-wrist-camera-3d-models-entire-body/',
      },
      {
        source: 'Interesting Engineering',
        url: 'https://interestingengineering.com/innovation/wristband-track-bodys-posture-in-3d',
      },
    ],
  },
  {
    title: 'EarIO',
    venue: 'IMWUT/Ubicomp \'22',
    subtitle: 'Published at IMWUT/Ubicomp \'22',
    link: '/research/EarIO',
    image: '/images/projects/eario.jpg',
    date: '2022-07-07',
    desc:
      'A Low-power Acoustic Sensing Earable for Continuously Tracking Detailed Facial Movements',

    paper_title: 'EarIO: A Low-power Acoustic Sensing Earable for Continuously Tracking Detailed Facial Movements',
    authors: 'Ke Li, Ruidong Zhang, Bo Liang, François Guimbretière, and Cheng Zhang',
    year: '2022',
    journal: 'Proc. ACM Interact. Mob. Wearable Ubiquitous Technol',
    publisher: '6, 2, Article 62 (July 2022), 24 pages',
    doi: 'https://doi.org/10.1145/3534621',
    video: 'https://www.youtube.com/embed/vy7uCH-PpaE',
    pub_status: 'Published',
    abstract:
      'This paper presents EarIO, an AI-powered acoustic sensing technology that allows an earable '
      + '(e.g., earphone) to continuously track facial expressions using two pairs of microphone and speaker '
      + '(one on each side), which are widely available in commodity earphones. It emits acoustic signals from '
      + 'a speaker on an earable towards the face. Depending on facial expressions, the muscles, tissues, and '
      + 'skin around the ear would deform differently, resulting in unique echo profiles in the reflected signals '
      + 'captured by an on-device microphone. These received acoustic signals are processed and learned by a '
      + 'customized deep learning pipeline to continuously infer the full facial expressions represented by 52 '
      + 'parameters captured using a TruthDepth camera. Compared to similar technologies, it has significantly '
      + 'lower power consumption, as it can sample at 86 Hz with a power signature of 154 mW. A user study with '
      + '16 participants under three different scenarios, showed that EarIO can reliably estimate the detailed '
      + 'facial movements when the participants were sitting, walking or after remounting the device. Based on '
      + 'the encouraging results, we further discuss the potential opportunities and challenges on applying EarIO '
      + 'on future ear-mounted wearables.',
    
    media_coverage: [
      {
        source: 'Cornell Chronicle',
        url: 'https://news.cornell.edu/stories/2022/07/earable-uses-sonar-reconstruct-facial-expressions',
      },
      {
        source: 'Hackster',
        url: 'https://www.hackster.io/news/you-sound-mad-06a7fc4200ad',
      },
      {
        source: 'New Atlas',
        url: 'https://newatlas.com/wearables/wearable-sonar-facial-expressions-eario/',
      },
    ],
  },
  {
    title: 'SpeeChin',
    venue: 'IMWUT/Ubicomp \'21',
    subtitle: 'Published at IMWUT/Ubicomp \'21',
    link: '/research/SpeeChin',
    image: '/images/projects/speechin.jpg',
    date: '2021-12-30',
    desc:
      'A Smart Necklace for Silent Speech Recognition',

    paper_title: 'SpeeChin: A Smart Necklace for Silent Speech Recognition',
    authors: 'Ruidong Zhang, Mingyang Chen, Benjamin Steeper, Yaxuan Li, Zihan Yan, Yizhuo Chen, Songyun Tao, Tuochao Chen, Hyunchul Lim, and Cheng Zhang',
    year: '2021',
    journal: 'Proc. ACM Interact. Mob. Wearable Ubiquitous Technol',
    publisher: '5, 4, Article 192 (December 2021), 23 pages',
    doi: 'https://doi.org/10.1145/3494987',
    video: 'https://www.youtube.com/embed/pDf86dSPYYI',
    pub_status: 'Published',
    abstract:
      'This paper presents SpeeChin, a smart necklace that can recognize 54 English and 44 Chinese silent speech '
      + 'commands. A customized infrared (IR) imaging system is mounted on a necklace to capture images of the neck '
      + 'and face from under the chin. These images are first pre-processed and then deep learned by an end-to-end '
      + 'deep convolutional-recurrent-neural-network (CRNN) model to infer different silent speech commands. A user '
      + 'study with 20 participants (10 participants for each language) showed that SpeeChin could recognize 54 English '
      + 'and 44 Chinese silent speech commands with average cross-session accuracies of 90.5% and 91.6%, respectively. '
      + 'To further investigate the potential of SpeeChin in recognizing other silent speech commands, we conducted '
      + 'another study with 10 participants distinguishing between 72 one-syllable nonwords. Based on the results from '
      + 'the user studies, we further discuss the challenges and opportunities of deploying SpeeChin in real-world applications.',
    
    media_coverage: [
      {
        source: 'Cornell Chronicle',
        url: 'https://news.cornell.edu/stories/2022/02/smart-necklace-recognizes-english-mandarin-commands',
      },
      {
        source: 'Gizmodo',
        url: 'https://gizmodo.com/seechin-wearable-smart-camera-voice-commands-1848548036',
      },
      {
        source: 'Hackster',
        url: 'https://www.hackster.io/news/speechin-sits-on-your-neck-and-films-your-chin-to-watch-for-silent-speech-commands-ad50d91da934',
      },
      {
        source: 'New Atlas',
        url: 'https://newatlas.com/wearables/speechin-necklace-silent-speech/',
      },
      {
        source: 'TechXplore',
        url: 'https://techxplore.com/news/2022-02-smart-necklace-silent-english-mandarin.html',
      },
    ],
  },
  {
    title: 'NeckFace',
    venue: 'IMWUT/Ubicomp \'21',
    subtitle: 'Published at IMWUT/Ubicomp \'21',
    link: '/research/NeckFace',
    image: '/images/projects/neckface.jpg',
    date: '2021-12-30',
    desc:
      'Continuously Tracking Full Facial Expressions on Neck-mounted Wearables',

    paper_title: 'NeckFace: Continuously Tracking Full Facial Expressions on Neck-mounted Wearables',
    authors: 'Tuochao Chen, Yaxuan Li, Songyun Tao, Hyunchul Lim, Mose Sakashita, Ruidong Zhang, François Guimbretière, and Cheng Zhang',
    year: '2021',
    journal: 'Proc. ACM Interact. Mob. Wearable Ubiquitous Technol',
    publisher: '5, 2, Article 58 (June 2021), 31 pages',
    doi: 'https://doi.org/10.1145/3463511',
    video: 'https://www.youtube.com/embed/PqsEky4LXhY',
    pub_status: 'Published',
    abstract:
      'Facial expressions are highly informative for computers to understand and interpret a person\'s mental and physical '
      + 'activities. However, continuously tracking facial expressions, especially when the user is in motion, is '
      + 'challenging. This paper presents NeckFace, a wearable sensing technology that can continuously track the full '
      + 'facial expressions using a neck-piece embedded with infrared (IR) cameras. A customized deep learning pipeline '
      + 'called NeckNet based on Resnet34 is developed to learn the captured infrared (IR) images of the chin and face '
      + 'and output 52 parameters representing the facial expressions. We demonstrated NeckFace on two common neck-mounted '
      + 'form factors: a necklace and a neckband (e.g., neck-mounted headphones), which was evaluated in a user study with '
      + '13 participants. The study results showed that NeckFace worked well when the participants were sitting, walking, '
      + 'or after remounting the device. We discuss the challenges and opportunities of using NeckFace in real-world applications.',

    media_coverage: [
      {
        source: 'Cornell Chronicle',
        url: 'https://news.cornell.edu/stories/2021/08/smart-necklace-could-track-your-detailed-facial-expressions',
      },
      {
        source: 'Hackster',
        url: 'https://www.hackster.io/news/face-your-emotions-156924f4ce21',
      },
      {
        source: 'New Atlas',
        url: 'https://newatlas.com/wearables/smart-necklace-facial-expressions-emotions-neckface/',
      },
    ],
  },
];

export default data;
